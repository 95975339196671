<template>
  <div class="AboutUsPart3">
    <div class="mission_content">
      <div class="mission_box" v-for="(item, index) in AboutUsPart3_Data" :key="index">
        <img :src="item.imgUrl" alt="">
        <div class="headline">{{ item.title }}</div>
        <div class="subtitle">{{ item.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutUsPart3_Data from '@/views/AboutUs/Data/AboutUsPart3_Data'

/**
 * @author  XuHongli
 * @date  2022/12/27 14:04
 * @version 1.0
 * @description
 */
export default {
  name: 'AboutUsPart3',
  data() {
    return {
      AboutUsPart3_Data
    }
  },
}
</script>

<style scoped lang="scss">
.AboutUsPart3{
  width: 100%;
  padding: 60px 0;
  position: relative;
  .mission_content{
    display: flex;
    justify-content: center;
    width: 1470px;
    margin: 0 auto;
    .mission_box{
      width: 380px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      &:hover{
        img{
          transform: scale(1.4);
        }
      }
      &:first-child{
        margin-right: 250px;
      }
      img{
        width: 236px;
        height: 257px;
        transition: all .4s;
      }
    }
    .headline{
      font-size: 32px;
      font-weight: 700;
      color: #333;
      margin-bottom: 40px;
    }
    .subtitle{
      font-size: 20px;
      color: #333;
      line-height: 34px;
    }
  }
}
</style>
