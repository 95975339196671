<template>
  <div class="AboutUsPart4">
    <div class="coverage_top">联系我们</div>
    <div class="contact_content">
      <div class="contact_introduce">
        <div
          :class="[
            'contact_introduce_nav',
            activity === index ? 'contact_introduce_nav_activity' : '',
          ]"
          v-for="(item, index) in AboutUsPart4_Data"
          :key="index"
          @click="change(index)"
        >
          <div class="contact_icon">
            <img :src="item.imgUrl" alt="" />
          </div>
          <div class="contact_box">
            <div class="contact_title">{{ item.title }}</div>
            <div class="contact_describe">{{ item.desc }}</div>
          </div>
        </div>
      </div>
      <div class="contact_map">
        <div>
          <img
            v-for="(item, index) in AboutUsPart4_Data"
            :key="index"
            :class="activity === index ? 'img' : ''"
            :src="item.rightImg"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AboutUsPart4_Data from "@/views/AboutUs/Data/AboutUsPart4_Data";

/**
 * @author  XuHongli
 * @date  2022/12/27 14:11
 * @version 1.0
 * @description
 */

export default {
  name: "AboutUsPart4",
  data() {
    return {
      AboutUsPart4_Data,
      activity: 0,
    };
  },
  methods: {
    change(index) {
      this.activity = index;
    },
  },
};
</script>

<style scoped lang="scss">
.AboutUsPart4 {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  width: 100%;
  padding: 60px 0;
  position: relative;
  background-image: url("https://resource.duobaoyu.com.cn/website/contact_bg.png");

  .coverage_top {
    font-size: 40px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei, serif;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
  }
}

.contact_content {
  display: flex;
  width: 1470px;
  height: 588px;
  margin: 60px auto 0;
  padding: 60px 70px;
  background: #fff;
  box-shadow: 0 0 46px 0 rgba(94, 94, 94, 0.19);

  .contact_introduce {
    margin-right: 30px;
    width: 485px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .contact_introduce_nav_activity {
      box-shadow: 0 0 10px rgba(56, 76, 254, 0.12);
      position: relative;

      &::before {
        content: "";
        width: 6px;
        height: 50%;
        border-radius: 10px;
        background: #fd0023;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .contact_introduce_nav {
      padding: 30px;
      width: 485px;
      height: 136px;
      display: flex;
      align-items: center;

      .contact_icon {
        display: inline-block;
        width: 75px;
        height: 75px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .contact_box {
        display: inline-block;
        margin-left: 30px;

        .contact_title {
          font-size: 20px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
          font-weight: 400;
          color: #333;
          margin-bottom: 18px;
        }

        .contact_describe {
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
          font-weight: 400;
          color: #666;
        }
      }
    }
  }

  .contact_map {
    width: 815px;
    position: relative;

    div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      img {
        display: none;
      }

      .img {
        display: block;
        animation-name: aa;
        animation-duration: 1s;
      }
    }
  }
}

@keyframes aa {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
