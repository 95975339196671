<template>
  <div class="AboutUsPart1">
    <div class="coverage_top">关于我们</div>
    <div class="brief_content">
      <div class="subtitle">
        <div class="subtitle_text">
          万鲸科技有限公司，致力于打造极具影响力的企业数字化服务平台，为企业赋能。
        </div>
        <div class="subtitle_text">
          公司专注于企业数字营销领域，覆盖APP、公众号、小程序、H5等渠道，高效匹配供应链，连接品牌、商家和用户三方诉求，致力于打造全链路数字化生态体系。
        </div>
        <div class="subtitle_text">
          公司从成立以来，已为全国众多企业提供专业的解决方案，助力企业快速转型，赋能企业搭建流量变现体系，精细化用户运营转型，激活存量，裂变增量。并为企业提供多种全渠道全场景的SAAS化应用，以及安全稳定、方便高效的技术支持，降低企业运营成本，提升企业利润。
        </div>
      </div>
      <div class="right_img">
        <img :src="$StaticFileUrl + '/mp/pages/AboutUs/brief_bg.png'" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author  XuHongli
 * @date  2022/12/27 11:16
 * @version 1.0
 * @description
 */
export default {
  name: "AboutUsPart1",
};
</script>

<style lang="scss" scoped>
.AboutUsPart1 {
  width: 100%;
  padding: 60px 0;
  position: relative;

  .coverage_top {
    font-size: 40px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei, serif;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
  }

  .brief_content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1470px;
    height: 460px;
    margin: 0 auto;

    .subtitle {
      width: 650px;
      margin-right: 50px;

      .subtitle_text {
        line-height: 36px;
        font-size: 18px;
        color: #333;
        margin: 20px 0;
        position: relative;

        &:before {
          content: "";
          width: 6px;
          height: 18px;
          background: #ff6a00;
          position: absolute;
          left: -20px;
          top: 10px;
        }
      }
    }
  }
  .right_img {
    width: 458px;
    height: 413px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
