<template>
  <div class="AboutUs">
    <div class="constent">
      <div class="header_nav">
        <div>赋能品牌力量&nbsp;&nbsp;&nbsp;呈现美好生活</div>
        <div>客 户 满 意 &nbsp;&nbsp;|&nbsp;&nbsp;员 工 温 暖&nbsp;&nbsp;|&nbsp;&nbsp;同 业 尊 敬&nbsp;&nbsp;|&nbsp;&nbsp;社 会 认 可</div>
      </div>
    </div>
    <AboutUsPart1></AboutUsPart1>
    <AboutUsPart2></AboutUsPart2>
    <AboutUsPart3></AboutUsPart3>
    <AboutUsPart4></AboutUsPart4>
  </div>
</template>

<script>
import AboutUsPart1 from '@/views/AboutUs/AboutUsPart1'
import AboutUsPart2 from '@/views/AboutUs/AboutUsPart2'
import AboutUsPart3 from '@/views/AboutUs/AboutUsPart3'
import AboutUsPart4 from '@/views/AboutUs/AboutUsPart4'
/**
 * @author  XuHongli
 * @date  2022/12/27 11:11
 * @version 1.0
 * @description
 */
export default {
  name: 'AboutUs',
  components: {AboutUsPart4, AboutUsPart3, AboutUsPart2, AboutUsPart1}
}
</script>

<style scoped lang="scss">
.constent{
  .header_nav{
    background-image: url("https://resource.duobaoyu.com.cn/website/bg.png");
    width: 100%;
    height: 560px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 40px;
    font-family: Microsoft YaHei-Regular,Microsoft YaHei,serif;
    color: #fff;
    padding-left: 230px;
    div{
      &:first-child{
        font-size: 46px;
        font-family: Microsoft YaHei-Bold,Microsoft YaHei,serif;
        font-weight: 700;
        color: #333;
      }
      &:last-child{
        font-size: 23px;
        font-family: Segoe UI-Regular,Segoe UI,serif;
        font-weight: 400;
        color: #666;
        margin-top: 22px;
      }
    }
  }
}
</style>
