/**
 * @author  XuHongli
 * @date  2022/12/27 14:02
 * @version 1.0
 * @description
 */

const AboutUsPart3_Data = [
  {
    title: '公司愿景',
    text: '致力于打造极具影响力的企业数字化服务平台。',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/mission_gsyj.png'
  },
  {
    title: '公司使命',
    text: ' 专注于科技创新，助力企业数字化转型。',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/mission_gssm.png'
  }
]

export default AboutUsPart3_Data
