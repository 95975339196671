/**
 * @author  XuHongli
 * @date  2022/12/27 11:49
 * @version 1.0
 * @description
 */
const AboutUsPart2_Data = [
  {
    type: 'img',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/ecology_img1.png'
  },
  {
    type: 'desc',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/khwzx.png',
    selectIcon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/khwzx_a.png',
    headline: '以客户为中心',
    subtitle: '客户至上，高效务实'
  },
  {
    type: 'img',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/ecology_img2.png'
  },
  {
    type: 'desc',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/cxgy.png',
    selectIcon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/cxgy_a.png',
    headline: '诚信共赢',
    subtitle: '诚信服务，合作共赢'
  },
  {
    type: 'img',
    imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/ecology_img3.png'
  },
  {
    type: 'desc',
    icon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/zqzy.png',
    selectIcon: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/zqzy_a.png',
    headline: '追求卓越',
    subtitle: '专业专注，创新极致'
  },
]

export default AboutUsPart2_Data
