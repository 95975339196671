<template>
  <div class="AboutUsPart2">
    <div class="coverage_top">公司价值观</div>
    <div class="ecology_content">
      <template v-for="(item, index) in AboutUsPart2_Data">
        <template v-if="item.type === 'img'">
          <div class="ecology_card" :key="index">
            <div class="ecology_img">
              <img :src="item.imgUrl" alt="">
            </div>
          </div>
        </template>
        <template v-if="item.type === 'desc'">
          <div class="ecology_card" :key="index" @mouseenter="activeKey = index" @mouseleave="activeKey = ''">
            <div class="ecology_desc">
              <img class="ecology_img1" :src="activeKey === index? item.selectIcon : item.icon" alt="">
              <div class="headline">{{ item.headline }}</div>
              <div class="subtitle">{{ item.subtitle }}</div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import AboutUsPart2_Data from '@/views/AboutUs/Data/AboutUsPart2_Data'

/**
 * @author  XuHongli
 * @date  2022/12/27 11:26
 * @version 1.0
 * @description
 */
export default {
  name: 'AboutUsPart2',
  data() {
    return {
      AboutUsPart2_Data,
      activeKey: ''
    }
  },
}
</script>

<style scoped lang="scss">
.AboutUsPart2{
  width: 100%;
  padding: 60px 0;
  background-color: #f7f7f7;
  position: relative;
  .coverage_top{
    font-size: 40px;
    font-family: Microsoft YaHei-Bold,Microsoft YaHei,serif;
    color: #333;
    text-align: center;
    margin-bottom: 30px;
  }
}
.ecology_content{
  display: flex;
  flex-wrap: wrap;
  width: 1470px;
  height: 746px;
  margin: 50px auto 0;
  background-color: #fff;
  .ecology_card{
    width: 490px;
    height: 373px;
    .ecology_img{
      overflow: hidden;
      width: 490px;
      height: 373px;
      &:hover{
        img{
          transform: scale(1.2);
        }
      }
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .4s;
      }
    }
    .ecology_desc{
      padding: 79px 0 80px;
      height: 100%;
      text-align: center;
      transition: all .4s;
      &:hover{
        background: #fc0017;
        box-shadow: 0 0 49px 0 rgba(163,0,0,.44);
        .headline,.subtitle{
          color: #fff;
        }
      }
      .ecology_img1{
        width: 110px;
        height: 100px;
      }
      .headline{
        color: #333;
        font-size: 28px;
        margin-top: 50px;
        margin-bottom: 10px;
      }
      .subtitle{
        color: #333;
        font-size: 18px;
      }
    }
  }
}
</style>
