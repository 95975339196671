/**
 * @author  XuHongli
 * @date  2022/12/27 14:13
 * @version 1.0
 * @description
 */
const AboutUsPart4_Data = [
    {
        title: '公司地址',
        desc: '广东省深圳市南山区北京航空航天大厦1座7003',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/consulting_address.png',
        rightImg: 'https://vllshop.com/img/poitR.32f258de.png'
    },
    {
        title: '合作邮箱',
        desc: 'jingdan@wonwhale.com',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/consulting_email.png',
        rightImg: 'https://vllshop.com/img/emailR.a7b68f06.png'
    },
    {
        title: '客服电话（7*24/小时）',
        desc: '0755-83899969',
        imgUrl: process.env.VUE_APP_STATIC_FILE_URL + '/mp/pages/AboutUs/consulting_phone.png',
        rightImg: 'https://vllshop.com/img/phoneR.3c99495e.png'

    }
]

export default AboutUsPart4_Data
